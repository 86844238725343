$topBarHeight: 50px;
$leftPanelWidth: 400px;

.topBar {
    background-color: #e54e04;
    height: $topBarHeight;
}
.brand {
    font-size: 1.3em;
    color: white;
    padding-left: 10px;
}
.loginUser {
    color: white;
    padding-right: 10px;
    margin-top: 4px;
}
.menuPanel {
    padding-right: 10px;
}
.dropDownButton {
    border: none;
    border-radius: 5px;
    background-color: white;
}
.menuButton {
    padding-right: 10px;
    color: white;
}
.dropDownPanel {
    width: 230px;
    margin-left: -190px;
    margin-top: 40px;
}

.pageContainer {
    height: calc(100% - 50px);
}

.leftPanel {
    float: left;
    width: $leftPanelWidth;
    height: calc(100vh - #{$topBarHeight});
}
.rightPanel {
    float: left;
    width: calc(100% - #{$leftPanelWidth});
    height: calc(100vh - #{$topBarHeight});
}
