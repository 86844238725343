.loginTop {
  .contents {
    text-align: center;
  }

  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  //background-color: rgba(112, 112, 112, 1);
  //background-color: rgba(224, 239, 225, 1);
  //background-color: rgba(255, 255, 255, 1);

  .mainTitle {
    color: black;
    margin-top: 5px;
    font-size: 1.6em;
  }
  .loginForm {
    padding: 30px 30px 30px 30px;
    margin-top: 1vh;
    display: inline-block;
    border-radius: 10px;
    background-color: #ffe1d3;
    .loginTitleLine {
      margin-bottom: 10px;
      .loginTitle {
        display: inline-block;
        text-align: right;
        padding-right: 20px;
        width: 130px;
      }
    }
    .loginButton {
      margin-top: 10px;
      height: 40px;
      width: 320px;
      color: black;
      background-color: #fd7e14;
      border-radius: 10px;
      border: none;
    }
    .disableButton {
      margin-top: 10px;
      height: 40px;
      width: 320px;
      color: black;
      background-color: #C3D5E4;
      border-radius: 10px;
      border: none;
    }
  }
  .passwordInformation {
    margin-top: 10px;
    .link {
      border-bottom: blue 1px solid;
      color: #0a58ca;
      cursor: pointer;
    }
  }
}
.link {
  color: #0a58ca;
  cursor: pointer;
}
